<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <div class="upimg">
        <div>
            <el-upload
                ref="upload"
                :action="baseUrl + '/upload/pic/3'"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :limit="5"
                :on-exceed="handleExceed"
                :file-list="showImg"
                accept="image/png, image/jpeg, image/jpg, image/bmp"
            >
                <div class="divadd">
                    <img :src="addpicIcon" alt="" class="addimg" /> <br />
                    添加图片
                </div>
            </el-upload>
        </div>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" />
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        ident: {
            type: Object,
            required: true,
        },
        imgl: {
            type: Array,
            required: false,
            default: function () {
                return [];
            },
        },
    },
    data() {
        return {
            ruleForm: {
                prove: [],
            },
            showImg: [],
            baseUrl: process.env.NODE_ENV === 'production' ? location.origin : '/proxy',
            dialogImageUrl: '',
            dialogVisible: false,
            addpicIcon: require('@/assets/img/addpic2.png'),
            fileList: [],
        };
    },
    methods: {
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            this.fileList = this.$refs.upload.uploadFiles;
            this.$store.commit('upImg', {
                file: this.fileList,
                ident: this.ident,
            });
            let obj = sessionStorage.getItem('upImg') || '{}';
            obj = JSON.parse(obj);
            obj[this.ident.id] = {
                file: this.fileList,
                ident: this.ident,
            };
            sessionStorage.setItem('upImg', JSON.stringify(obj));
        },
        beforeAvatarUpload(file) {
            const isJPEG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isBMP = file.type === 'image/bmp';
            const isLt2M = file.size / 1024 / 1024 < 50;
            if (!isJPEG && !isPNG && !isBMP) {
                this.$message.error('上传证件只能是 JPEG/PNG/BMP 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传证件照片大小不能超过 50MB!');
            }
            return isLt2M;
        },
        handleRemove(file, fileList) {
            this.fileList = this.$refs.upload.uploadFiles;
            this.$store.commit('upImg', {
                file: this.fileList,
                ident: this.ident,
            });
            let obj = sessionStorage.getItem('upImg') || '{}';
            obj = JSON.parse(obj);
            obj[this.ident.id] = {
                file: this.fileList,
                ident: this.ident,
            };
            sessionStorage.setItem('upImg', JSON.stringify(obj));
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleExceed(files, fileList) {
            this.$message.warning('图片材料最多上传5张');
        },
    },
    watch: {
        imgl: {
            deep: true,
            handler: function (newV, oldV) {
                let showArr = [];
                newV.forEach((item) => {
                    showArr.push({
                        response: { data: [item.url] },
                        url: item.url,
                    });
                });
                this.showImg = showArr;
                this.fileList = newV;
                let obj = sessionStorage.getItem('upImg') || '{}';
                obj = JSON.parse(obj);
                let arr = [];
                this.fileList.forEach((item) => {
                    item = {
                        url: item.url,
                        response: { data: [item.url] },
                    };
                    arr.push({
                        response: { data: [item.url] },
                        url: item.url,
                    });
                });
                this.fileList = arr;
                obj[this.ident.id] = {
                    file: arr,
                    ident: this.ident,
                };
                sessionStorage.setItem('upImg', JSON.stringify(obj));
            },
        },
    },
    mounted() {
        sessionStorage.removeItem('upImg');
        // if (location.hostname === 'localhost') {
        //     this.baseUrl = '/proxy';
        // } else if (location.hostname === '127.0.0.1') {
        //     this.baseUrl = '/proxy';
        // } else {
        //     this.baseUrl = location.origin;
        // }
    },
};
</script>
<style>
[class*=' el-icon-'],
[class^='el-icon-'] {
    color: #d3d1e0;
}
[class*=' el-icon-'],
[class^='el-icon-']:hover {
    color: #fff;
}
</style>
<style lang="less" scoped>
.upimg {
    margin: 2px 0 10px 0;
}
::v-deep .el-upload-list--picture-card {
    display: inline-block;
    height: 54px;
}
::v-deep .el-upload-list__item {
    transition: none;
}
::v-deep .el-upload-list__item.is-success .el-upload-list__item-status-label {
    display: none;
}
::v-deep .el-checkbox__label {
    position: relative;
    top: 1px;
}
/deep/ .el-upload {
    width: 72px;
    height: 54px;
}
/deep/ .el-checkbox-group {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: left;
    .el-checkbox {
        align-self: flex-start;
    }
    .el-upload-list__item {
        width: 72px;
        height: 54px;
    }
}
.divadd {
    font-size: 12px;
    color: #b0b2c4;
    width: 72px;
    height: 54px;
    line-height: 16px;
    // background-color: red;
}
.addimg {
    width: 16px;
    height: 16px;
    margin-top: 10px;
}
/deep/ .el-dialog__header {
    padding: 0px;
}
/deep/ .el-dialog__body {
    padding: 0px 20px 0px 20px;
}
</style>
