<!--
 * @Author: v_yerun v_yerun@baidu.com
 * @Date: 2025-02-13 10:51:22
 * @LastEditors: v_yerun v_yerun@baidu.com
 * @LastEditTime: 2025-02-25 11:49:32
 * @FilePath: /copyright/src/components/ownership/ObligeeOwnership.vue
 * @Description: 权属登记-权属信息-权利人
-->
<template>
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        label-position="right"
        :rules="rules"
        label-width="125px"
        size="small"
        class="demo-ruleForm clearfix"
    >
        <el-form-item label="作品名称" prop="workName">
            <el-input
                v-model.trim="ruleForm.workName"
                maxlength="20"
                placeholder="请输入作品名称"
                @change="selectChange"
            ></el-input>
        </el-form-item>
        <el-form-item label="作品类型" prop="workType">
            <el-select v-model="ruleForm.workType" placeholder="请选择作品类型" @change="selectChange">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
            </el-select>
            <div v-show="off" class="popdialog">
                此名称已存在，可
                <el-button type="text" @click="dialogTableVisible = true">点击此处</el-button>
                重新选择
            </div>
            <el-dialog
                top="100px"
                width="900px"
                custom-class="dialog-ownership"
                :visible.sync="dialogTableVisible"
                :show-close="true"
                :destroy-on-close="true"
            >
                <pop-ownership
                    v-if="dialogTableVisible"
                    :work-name="ruleForm.workName"
                    :work-type="ruleForm.workType"
                >
                </pop-ownership>
            </el-dialog>
        </el-form-item>
        <el-form-item
            v-if="pseudonymShowFlag"
            label="作者名称/笔名"
            prop="pseudonym"
        >
            <el-input
                v-model.trim="ruleForm.pseudonym"
                placeholder="请输入笔名"
                @change="selectChange"
            />
        </el-form-item>
        <el-form-item
            label="权属证明"
            prop="prove"
        >
            <el-checkbox-group v-model="ruleForm.prove">
                <el-checkbox label="作品登记证书" name="prove"></el-checkbox>
                <up-img
                    v-show="ruleForm.prove.includes('作品登记证书')"
                    :ident="{
                        id: 'registryBookz',
                        label: '作品登记证书',
                    }"
                    :imgl="imgObj.registryBookz"
                ></up-img>
                <el-checkbox label="平台首发截图" name="prove"></el-checkbox>
                <up-img
                    v-show="ruleForm.prove.includes('平台首发截图')"
                    :ident="{
                        id: 'releaseScreenshotz',
                        label: '平台首发截图',
                    }"
                    :imgl="imgObj.releaseScreenshotz"
                ></up-img>
                <p v-show="ruleForm.prove.includes('平台首发截图')" class="statement">
                    （作品首次发布平台的页面截图、作品首次发布平台的帐号后台注册信息截图）
                </p>
                <el-checkbox label="影视公映许可证或出品方署名截图" name="prove"></el-checkbox>
                <up-img
                    v-show="ruleForm.prove.includes('影视公映许可证或出品方署名截图')"
                    :ident="{
                        id: 'publicScreenshotz',
                        label: '影视公映许可证或出品方署名截图',
                    }"
                    :imgl="imgObj.publicScreenshotz"
                ></up-img>
                <el-checkbox label="软件著作权登记证书" name="prove"></el-checkbox>
                <up-img
                    v-show="ruleForm.prove.includes('软件著作权登记证书')"
                    :ident="{
                        id: 'copyrightBookz',
                        label: '软件著作权登记证书',
                    }"
                    :imgl="imgObj.copyrightBookz"
                ></up-img>
                <el-checkbox label="游戏ISBN号核发单" name="prove"></el-checkbox>
                <up-img
                    v-show="ruleForm.prove.includes('游戏ISBN号核发单')"
                    :ident="{
                        id: 'ISBNNumz',
                        label: '游戏ISBN号核发单',
                    }"
                    :imgl="imgObj.ISBNNumz"
                ></up-img>
                <el-checkbox label="版权授权证明文件" name="prove"></el-checkbox>
                <up-img
                    v-show="ruleForm.prove.includes('版权授权证明文件')"
                    :ident="{
                        id: 'copyrightFilez',
                        label: '版权授权证明文件',
                    }"
                    :imgl="imgObj.copyrightFilez"
                ></up-img>
                <p v-show="ruleForm.prove.includes('版权授权证明文件')" class="statement">
                    （需要版权授权的权利链条完整、明确）
                </p>
                <el-checkbox label="其他权属证明文件" name="prove"></el-checkbox>
                <up-img
                    v-show="ruleForm.prove.includes('其他权属证明文件')"
                    :ident="{
                        id: 'otherFilez',
                        label: '其他权属证明文件',
                    }"
                    :imgl="imgObj.otherFilez"
                ></up-img>
            </el-checkbox-group>
        </el-form-item>
        <el-form-item label="版权权属有效期" prop="time">
            <el-date-picker
                v-model="ruleForm.time"
                class="picker"
                type="daterange"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                :default-time="['00:00:00', '23:59:59']"
                @change="onPick"
            >
            </el-date-picker>
            <el-checkbox v-model="ruleForm.longTime" class="longTime" @change="longChange">长期有效</el-checkbox>
        </el-form-item>
        <el-form-item class="last">
            <div class="tips">
                <p class="p1">提示</p>
                <div class="tips_font">
                    <div class="circle"></div>
                    <p>
                        请您上传有效的权属证明文件，包括但不限于作品登记证书、创作手稿、作品首次公开发表或发行日期证明材料、
                        首次发布平台页面截图、公映许可证、作品署名截图、图书或光盘载有版权标识的页面、软件著作权登记证书、
                        出版运营游戏批复文件、游戏ISBN号核发单、权利链条完整的版权授权文件等能证明权利人拥有相关权利的有效权属证明
                    </p>
                    <div class="circle1"></div>
                    <p>文件格式仅限于图片格式：jpg/png/jpeg/bmp，大小不超过50M，附件总和不超过25个</p>
                </div>
            </div>
        </el-form-item>
        <el-form-item>
            <next-tick
                :btn1="0"
                :btn2="0"
                :btn3="1"
                :btn4="1"
                class="tick"
                @nextTick="nextTick('ruleForm')"
            ></next-tick>
        </el-form-item>
    </el-form>
</template>

<script>
import NextTick from '@/components/NextTick.vue';
import PopOwnership from '@/components/ownership/PopOwnership.vue';
import { judge, ownership } from '@/api/getData.js';
import UpImg from '@/components/publicComponents/UpImg.vue';
import { getType, rTime } from '@/utils/public.js';

export default {
    name: 'obligeeownership',
    components: {
        'next-tick': NextTick, // 上一步下一步按钮
        'pop-ownership': PopOwnership, // 弹窗
        UpImg,
    },
    data() {
        var validPseudonym = (rule, value, callback) => {
            if (!value || value.length <= 0) {
                callback(new Error('请填写作者名称/笔名信息'));
            } else if (value.length > 50) {
                callback(new Error('作者名称/笔名超过50字符'));
            } else {
                callback();
            }
        };
        return {
            dialogImageUrl: '',
            baseUrl: process.env.NODE_ENV === 'production' ? location.origin : '/proxy',
            dialogVisible: false,
            addpicIcon: require('@/assets/img/addpic2.png'),
            fileList1: [],
            fileList2: [],
            fileList3: [],
            fileList4: [],
            fileList5: [],
            maxImg: {},
            imgObj: {},
            off: false,
            showClo: false,
            dialogTableVisible: false,
            options: [], // 作品类型 枚举列表 注：value值与顺序非一致
            pseudonymShowFlag: false,
            ruleForm: {
                workName: '', // 作品名称
                pseudonym: '', // 笔名
                workType: '', // 作品类型
                prove: [], // 权属证明多选框
                time: '', // 证件有效期
                longTime: false,
            },
            rules: {
                workName: [
                    { required: true, message: '请输入作品名称', trigger: 'change' },
                    {
                        min: 1,
                        max: 40,
                        message: '长度在 40 个字符以内',
                        trigger: 'blur',
                    },
                ],
                prove: [
                    {
                        type: 'array',
                        required: true,
                        message: '请选择权属证明',
                        trigger: 'change',
                    },
                ],
                pseudonym: [
                    {
                        required: true,
                        validator: validPseudonym,
                        trigger: 'blur'
                    },
                ],
                workType: [{ required: true, message: '请选择作品类型', trigger: 'change' }],
                time: [
                    { required: true, message: '请选择证件有效期', trigger: ['blur', 'change'] },
                    { validator: this.validateTime, trigger: ['blur', 'change'] },
                ],
            },
        };
    },
    watch: {

      'ruleForm.workType': {
        immediate: true,
        handler(newValue) {
          this.pseudonymShowFlag = newValue === '1';
          if (!this.pseudonymShowFlag) {
            this.ruleForm.pseudonym = '';
          }
        }
      }
    },
    methods: {
        longChange() {
            // 权属长期有效
            if (this.ruleForm.longTime) {
                this.ruleForm.time = [rTime('2000-01-01'), rTime('2099-12-31')];
            } else {
                this.ruleForm.time = [];
            }
        },
        /**
         * @description: // 下一步
         * @param {*} ruleForm
         * @return {*}
         */
        nextTick(ruleForm) {
            let own = sessionStorage.getItem('own');
            if (own) {
                try {
                    own = JSON.parse(own);
                } catch (e) {
                    own = {};
                }
            }
            if (this.off === true) {
                this.$message.error('此名称已存在，请重新选择');
                return;
            }
            // 将表单信息缓存起来
            this.$refs[ruleForm].validate((valid, rule) => {
                if (valid) {
                    // 信息填写完整
                    let arr = this.checkImg();
                    if (!Array.isArray(arr) || arr.length <= 0) {
                        this.$message.error('请上传图片材料');
                        return;
                    }
                    let ownershipObj = sessionStorage.getItem('ownership') || '{}';
                    try {
                        ownershipObj = JSON.parse(ownershipObj);
                    } catch (e) {
                        ownershipObj = {};
                    }
                    let data = {
                        ...ownershipObj,
                        ...this.ruleForm,
                        cp_id: '',
                        arr,
                        pType: 1,
                        updata: false,
                    };
                    sessionStorage.setItem('ownership', JSON.stringify(data));
                    if (own.type) {
                        own.type = false;
                        sessionStorage.setItem('own', JSON.stringify(own));
                    }
                    sessionStorage.setItem('active', 2);
                    this.$router.push({
                        name: 'complaint',
                        params: {
                            name: this.ruleForm.workName,
                            type: getType(this.ruleForm.workType),
                        },
                    });
                } else {
                    let hasError = false; // 标记是否有错误信息
                    for (const key in rule) {
                        if (rule[key]?.[0]?.message) {
                            this.$message.error(rule[key][0].message);
                            hasError = true;
                            break; // 终止循环，避免多个弹窗
                        }
                    }
                    if (!hasError) {
                        // 只有当所有 rule 都没有 message 时才执行
                        this.$message.error('请将信息填写完整!');
                    }
                    return;
                }
            });
        },
        /**
         * @description: 判断是否选择了图片
         * @return {*}
         */
        checkImg() {
            // 获取图片列表信息
            let imgList = {};
            imgList = sessionStorage.getItem('upImg') || '{}';
            try {
                imgList = JSON.parse(imgList);
            } catch (e) {
                imgList = {};
            }
            let arr = [];
            let num = 0;
            // 遍历权属证明选项，判断每个选项是否选择了图片
            this.ruleForm.prove.forEach((item) => {
                for (let key in imgList) {
                    if (imgList[key].ident.label === item && imgList[key].file.length) {
                        num++;
                        arr.push(imgList[key]);
                    }
                }
            });
            // 上传图片的权限证明和所有权属证明数量不一致即为存在未上传图片的情况，提示用户完善图片材料
            if (num !== this.ruleForm.prove.length) {
                return false;
            }
            // 一张图片都没有上传，提示用户完善图片材料
            if (!arr.length) {
                return false;
            }
            return arr;
        },
        selectChange() {
            judge({
                type: this.ruleForm.workType,
                workName: encodeURIComponent(this.ruleForm.workName),
            }).then((res) => {
                this.off = res.data;
            });
        },
        /**
         * @description: 验证有效期
         * @return {*}
         */
        validateTime(rule, value, callback) {
            if (!Array.isArray(value) || value?.length < 2) {
                callback(new Error('请选择证件有效期'));
            } else if (Date.parse(value[1]) - Date.parse(new Date()) > 0) {
                callback();
            } else {
                callback(new Error('证件结束时间应当大于当前时间'));
            }
        },
        /**
         * @description: 证件有效期选择器回调函数
         * @param {*} maxDate
         * @return {*}
         */
        onPick(maxDate) {
            if (maxDate === null) {
                this.ruleForm.longTime = false;
                return;
            }
            let startTime = rTime(this.ruleForm.time[0]);
            let endTime = rTime(this.ruleForm.time[1]);
            if (startTime === '2000-01-01' && endTime === '2099-12-31') {
                this.ruleForm.longTime = true;
            } else {
                this.ruleForm.longTime = false;
            }
            if (this.ruleForm.time === null) {
                return;
            }
            this.$refs.ruleForm.validateField('time'); // 触发校验
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            const isJPEG = file.type === 'image/jpeg';
            const isJPG = file.type === 'image/jpg';
            const isPNG = file.type === 'image/png';
            const isBMP = file.type === 'image/bmp';
            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isJPG && !isJPEG && !isPNG && !isBMP) {
                this.$message.error('上传证件只能是 JPG/JPEG/PNG/BMP 格式!');
            }
            if (!isLt5M) {
                this.$message.error('上传证件照片大小不能超过 5MB!');
            }
            return isLt5M;
        },
        handleRemove(file, fileList) {},
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${fileList.length} 个文件`
            );
        },
    },
    mounted() {
        this.selectChange({
            type: [],
            workName: '',
        });
        this.options = this.$store.getters.getOptionsArr; // 获取作品类型列表
        let own = sessionStorage.getItem('own');
        if (own) {
            own = JSON.parse(own);
        }
        let dic = [
            {
                id: 'registryBookz',
                label: '作品登记证书',
                name: 'registryBook',
            },
            {
                id: 'releaseScreenshotz',
                label: '平台首发截图',
                name: 'releaseScreenshot',
            },
            {
                id: 'publicScreenshotz',
                label: '影视公映许可证或出品方署名截图',
                name: 'publicScreenshot',
            },
            {
                id: 'copyrightBookz',
                label: '软件著作权登记证书',
                name: 'copyrightBook',
            },
            {
                id: 'ISBNNumz',
                label: '游戏ISBN号核发单',
                name: 'ISBNNum',
            },
            {
                id: 'copyrightFilez',
                label: '版权授权证明文件',
                name: 'copyrightFile',
            },
            {
                id: 'otherFilez',
                label: '其他权属证明文件',
                name: 'otherFile',
            },
        ];
        if (own && own.type) {
            ownership({
                id: own.cp_id,
            }).then((res) => {
                if (res.data.owner_type === 1) {
                    this.ruleForm.workName = res.data.works_name;
                    this.ruleForm.workType = res.data.works_category + '';
                    this.ruleForm.pseudonym = res.data?.pseudonym;
                    let startTime = rTime(res.data.works_start_date);
                    let endTime = rTime(res.data.works_end_date);
                    if (startTime === '2000-01-01' && endTime === '2099-12-31') {
                        this.ruleForm.longTime = true;
                        this.ruleForm.time = [rTime('2000-01-01'), rTime('2099-12-31')];
                    } else if (startTime && endTime) {
                        this.ruleForm.longTime = false;
                        this.ruleForm.time = [startTime, endTime];
                    }
                    let arr = JSON.parse(res.data.works_certificate_url);
                    let imgarr = [];
                    for (let key in arr) {
                        dic.forEach((item) => {
                            if (key === item.name) {
                                let arrs = [];
                                arr[key].forEach((items) => {
                                    arrs.push({
                                        response: {
                                            data: [items],
                                        },
                                    });
                                });
                                this.ruleForm.prove.push(item.label);
                                imgarr.push({
                                    ident: item,
                                    file: arrs,
                                });
                            }
                        });
                    }
                    this.maxImg = imgarr;
                    imgarr.forEach((item) => {
                        let imgarrs = [];
                        item.file.forEach((items) => {
                            imgarrs.push({
                                url: items.response.data[0],
                            });
                        });
                        this.imgObj[item.ident.id] = imgarrs;
                    });
                }
            });
        } else {
            let ruleForm = sessionStorage.getItem('ownership');
            if (ruleForm) {
                let data = JSON.parse(ruleForm);
                if (data?.pType === 1) {
                    data.workType += '';
                    this.ruleForm = data;
                    data.arr.forEach((item) => {
                        let imgarr = [];
                        item.file.forEach((items) => {
                            imgarr.push({
                                url: items.response.data[0],
                            });
                        });
                        this.imgObj[item.ident.id] = imgarr;
                    });
                }
            }
        }
        //   if (location.hostname === 'localhost') {
        //       this.baseUrl = '/proxy';
        //   } else if (location.hostname === '127.0.0.1') {
        //       this.baseUrl = '/proxy';
        //   } else {
        //       this.baseUrl = location.origin;
        //   }
    },
};
</script>
<style>
.el-select-dropdown__item.selected {
    color: #2d63e0;
}
.el-date-table td.available:hover {
    color: #2d63e0 !important;
}
.el-date-table td.end-date span {
    background-color: #2d63e0;
}
.el-date-table td.start-date span {
    background-color: #2d63e0;
}
.el-date-table td span {
    border-radius: 2px;
}
.el-date-table td.in-range div {
    background-color: rgba(45, 99, 224, 0.1);
}
.el-date-table td.in-range div:hover {
    background-color: rgba(45, 99, 224, 0.1);
}
.el-date-table.is-week-mode .el-date-table__row.current div {
    background-color: rgba(45, 99, 224, 0.1);
}
.el-date-table.is-week-mode .el-date-table__row:hover div {
    background-color: rgba(45, 99, 224, 0.1);
}
.el-date-table td.end-date div {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.el-date-table td.start-date div {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.el-date-table td.today span {
    color: #2d63e0;
}
.el-checkbox__label {
    position: relative;
    top: 1px;
}
</style>
<style lang="less" scoped>
::v-deep .el-checkbox.longTime {
    display: inline-block;
    margin-left: 50px;
}
.statement {
    color: rgb(181, 181, 181);
    font-size: 14px;
    margin-top: -5px;
}
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
    margin-bottom: 28px;
}
::v-deep .is-checked .el-checkbox__inner {
    background-color: #2d63e0;
    border-color: #2d63e0;
}
::v-deep .el-upload--picture-card:hover {
    border: 1px dashed #c0ccda !important;
    .divadd {
        color: #2d63e0 !important;
    }
}
::v-deep .el-upload:focus {
    border: 1px dashed #c0ccda !important;
}
.el-form-item {
    font-size: 14px;
    color: #383d47;
    text-align: left;
    .popdialog {
        font-size: 12px;
        color: #e63232;
        letter-spacing: 0;
        line-height: 12px;
        text-align: left;
        width: 420px;
        height: 12px;
        ::v-deep .el-button--text {
            color: #2d63e0;
        }
    }
}
.el-input {
    width: 420px;
    height: 32px;
}

.el-select {
    width: 420px;
    height: 32px;
}

.picker {
    width: 420px;
    height: 32px;
    text-align: left;
}

.el-checkbox {
    display: block;
}

.tips {
    width: 420px;
    height: 159px;
    background: #f5f7fc;
    border-radius: 4px;
    margin-top: -10px;
    background: url(../../assets/img/Tips.png) 100% 100% no-repeat;
    background-size: 100% 100%;
}
.tips .p1 {
    width: 24px;
    height: 12px;
    font-size: 12px;
    font-weight: bold;
    color: #383d47;
    letter-spacing: 0;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 12px;
}
.tips .circle {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #3167dd;
    position: absolute;
    left: -8px;
    top: 7px;
}
.tips .circle1 {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #3167dd;
    position: absolute;
    left: -8px;
    top: 97px;
}
.tips_font {
    width: 388px;
    height: 123px;
    font-size: 12px;
    color: #767981;
    letter-spacing: 0;
    text-align: left;
    line-height: 18px;
    margin: auto;
    position: relative;
}

.tick {
    text-align: left;
}

/deep/ .el-upload {
    width: 72px;
    height: 54px;
}

/deep/ .el-checkbox-group {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: left;

    .el-checkbox {
        align-self: flex-start;
    }

    .el-upload-list__item {
        width: 72px;
        height: 54px;
    }
}

.divadd {
    font-size: 12px;
    color: #b0b2c4;
    width: 72px;
    height: 54px;
    line-height: 16px;
    // background-color: red;
}
.addimg {
    width: 16px;
    height: 16px;
    margin-top: 10px;
}
/deep/ .el-dialog__header {
    padding: 0px;
}
/deep/ .el-dialog__body {
    padding: 20px 20px 20px 20px;
    border-radius: 5px;
}
/deep/.dialog-ownership {
    .popup_main_top_search {
        text-align: center;
    }
    .el-pagination {
        text-align: center;
    }
    .table-pagination {
        text-align: center;
    }
    .el-table {
        height: 100% !important;
    }
}
</style>